import React, { useContext, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  HelperBlockCardPreview,
  HelperBlockEditorItemList,
  HelperBlockEditorItemListPreview,
  HelperBlockContextCard,
  HelperDisplayCheckerSetterHelper,
} from "./_index.js";
import { Loader, EditorContext, MyTextEditor, MyButton } from "../../lib";
import pink_pie from "./images/food/pink_pie.svg";
import parse from "html-react-parser";

import Style from "./BlockAll.module.scss";

const blockColor = "#FDCBCB";
const blockTextColor = "#FDCBCB";
const blockName = "Benefits";

const block_benefits_data_generator = () => {
  const new_uuid = uuid();

  return {
    key: new_uuid,
    data: {
      version: 1,
      id: new_uuid, //block
      type: "benefits",
      content: {
        benefits_main_list: [],
      },
    },
  };
};

const BlockBenefitsPreview = (props) => {
  // Content items
  const selectedBlock = useContext(EditorContext).selectedBlock;
  const blockDictionary = useContext(EditorContext).blockDictionary;
  const currentStep = useContext(EditorContext).currentStep;

  const [isContent, setIsContent] = useState(false);

  const _contentValueHelper = (contentKey) => {
    if (
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        "" ||
      blockDictionary[props.blockId].content[props.blockId + contentKey] ===
        undefined
    ) {
      return "";
    } else {
      return blockDictionary[props.blockId].content[props.blockId + contentKey];
    }
  };

  const contentValueArray = ["title", "body"];
  const contentListArray = ["benefits_main_list"];

  useEffect(() => {
    HelperDisplayCheckerSetterHelper(
      contentValueArray,
      contentListArray,
      blockDictionary,
      props.blockId,
      setIsContent
    );
    return () => {
      HelperDisplayCheckerSetterHelper(
        contentValueArray,
        contentListArray,
        blockDictionary,
        props.blockId,
        setIsContent
      );
    };
  }, []);

  useEffect(() => {
    HelperDisplayCheckerSetterHelper(
      contentValueArray,
      contentListArray,
      blockDictionary,
      props.blockId,
      setIsContent
    );
  }, [selectedBlock]);

  if (props.publicRender && !isContent) {
    return null;
  }

  return (
    <>
      {blockDictionary[props.blockId] === null && <Loader />}
      {blockDictionary[props.blockId] !== null && !isContent && (
        <div
          className={Style.emptyBlock}
          style={{ borderColor: blockColor, color: blockColor }}
        >
          <h2>{blockName}</h2>
          <p>Click to add content</p>
        </div>
      )}
      {blockDictionary[props.blockId] !== null && isContent && currentStep !== "block_editor" && (
        <div className={"jc_block"}>
          <div className="style_jc benefits_jc">
            {parse(_contentValueHelper("title"))}
            {parse(_contentValueHelper("body"))}

            <HelperBlockEditorItemListPreview
              listName={"benefits_main_list"}
              blockId={props.blockId}
            />
          </div>
        </div>
      )}
      {blockDictionary[props.blockId] !== null && isContent && currentStep === "block_editor" && (
          <>
            {parse(_contentValueHelper("title"))}
            {parse(_contentValueHelper("body"))}

            <HelperBlockEditorItemListPreview
              listName={"benefits_main_list"}
              blockId={props.blockId}
            />
          </>
      )}
    </>
  );
};

const BlockBenefitsCardPreview = (props) => {
  return (
    <HelperBlockCardPreview
      image={pink_pie}
      header="Benefits"
      short_description="Help candidates see why they'll love working at your company as much as you do."
      block_type="benefits"
      color="--red-medium"
      color_light="#FDCBCB"
    />
  );
};

const BlockBenefitsEditor = (props) => {
  const setSelectedBlock = useContext(EditorContext).setSelectedBlock;

  return (
    <>
      <div className={Style.editorCloseButtonWrapper}>
        <h3
          className={Style.sectionTitle}
          style={{ color: blockTextColor, textTransform: "uppercase" }}
        >
          {blockName}
        </h3>
        <MyButton
          buttonStyle="secondary"
          size="small"
          onClick={() => {
            setSelectedBlock(null);
          }}
        >
          Close
        </MyButton>
      </div>
      <br />
      <p className={Style.sectionSubtitle}>Section title :</p>
      <MyTextEditor
        blockId={props.blockId}
        contentKey={props.blockId + "title"}
        placeholderText={"Type a summary section title..."}
        contentBlockType="header-two"
        tabIndex={1}
        quickAddOptions={["Benefits"]}
      />
      <p className={Style.sectionSubtitle}>
        Short introduction on the benefits :
      </p>
      <MyTextEditor
        blockId={props.blockId}
        contentKey={props.blockId + "body"}
        placeholderText={"Type the summary for this job..."}
        tabIndex={2}
        ctaViewSampleText
      />
      <p className={Style.sectionSubtitle}>List of key benefits :</p>
      <HelperBlockEditorItemList
        listName={"benefits_main_list"}
        blockId={props.blockId}
        placeholderText={"Type a benefit..."}
        tabIndex={3}
        ctaViewSampleText
      />
    </>
  );
};

const BlockBenefitsContext = (props) => {
  // const [toggleFormat, setToggleFormat] = useState("list");

  return (
    <HelperBlockContextCard
      header={"BENEFITS"}
      blockType={"benefits"}
      color="#FDCBCB"
      image={pink_pie}
      iconStyle={Style.salmon}
    />
  );
};

export {
  BlockBenefitsPreview,
  BlockBenefitsCardPreview,
  BlockBenefitsEditor,
  BlockBenefitsContext,
  block_benefits_data_generator,
};
