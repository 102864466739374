import React, { useState } from "react";
import Style from "./myButton.module.scss";
import { Icon } from "components/icon/icon";

export function MyButton(props) {
  let loading = props.loading;

  const hasSVG = props.hasOwnProperty("svg");
  const hasIcon = props.hasOwnProperty("icon");
  const isLoadingButton = props.hasOwnProperty("loading");
  const isIconButton = props.children === undefined;

  let block = props.hasOwnProperty("block") ? Style.myBlockButton : "";
  let loadingStyle = loading ? Style.displayDuringLoad : "";
  let isActive = props.active ? Style.active : "";
  let style = "";
  let size = "";
  let iconButton = isIconButton ? Style.iconButton : "";

  const newProps = {
    ...props.type ? { type: props.type } : ""
  };


  switch (props.size) {
    case "small":
      size = Style.mySmallButton;
      break;

    case "medium":
      size = Style.myMediumButton;
      break;

    case "large":
      size = Style.myLargeButton;
      break;

    default:
      size = Style.mySmallButton;
      break;
  }

  switch (props.buttonStyle) {
    case "primary":
      style = Style.myPrimaryButton;
      break;
    case "secondary":
      style = Style.mySecondaryButton;
      break;
    case "alternative":
      style = Style.myAlternativeButton;
      break;

    case "danger":
      style = Style.myDangerButton;
      break;

    case "ghost":
      style = Style.myGhostButton;
      break;

    case "outline":
      style = Style.myOutlineButton;
      break;

    case "disabled":
      style = Style.myDisabledButton;
      break;

    default:
      style = Style.mySecondaryButton;
      break;
  }

  return (
    <button
      className={
        Style.myButton +
        " " +
        style +
        " " +
        size +
        " " +
        block +
        " " +
        isActive +
        " " +
        iconButton +
        " " +
        props.className
      }
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!loading && props.onClick) {
          props.onClick();
        }
      }}
      {...newProps}
    >
      {hasIcon && !loading && (
        <div className={Style.img_wrapper + " " + loadingStyle}>
          <Icon image={props.icon} className={Style.icon} />
        </div>
      )}
      {hasSVG && !loading && (
        <div className={Style.img_wrapper + " " + loadingStyle}>
          <img src={props.svg} alt="button icon" className={Style.img} />
        </div>
      )}
      {!loading && props.children}
      {loading && (
        <div className={Style.loading}>
          <Icon image={"loader"} size={props.size} className={Style.icon} />
        </div>
      )}
    </button>
  );
}
