import React, { useContext } from "react";
import { MyButton, EditorContext } from "components/lib";
import Style from "./emptyContentPageModal.module.scss";
import Modal from "@material-ui/core/Modal";
import { ReactComponent as Classic } from "./img/classic.svg";
import { ReactComponent as Empty } from "./img/empty.svg";

const EmptyContentPageModal = (props) => {
  const addBlockHelper = useContext(EditorContext).addBlockHelper;
  const blockOrder = useContext(EditorContext).blockOrder;
  const setSelectedBlock = useContext(EditorContext).setSelectedBlock;
  const setSideElem = props.setSideElem;
  const setOpenedDrawer = props.setOpenedDrawer;

  return (
    <div className={Style.modalWrapper}>
      <Modal
        open={props.showEmptyContentPageModal}
        onClose={() => {
          // props.setShowEmptyContentPageModal(false);
        }}
      >
        <div className={Style.modalMain}>
          <div>
            <h2>Start with a template?</h2>
            <p>
              Select one of the options below to start editing your content.
            </p>
          </div>
          <div className={Style.templateOptionsWrapper}>
            <div className={Style.templateOption}>
              <h3>Classic</h3>
              <div className={Style.imageDescriptionWrapper}>
                <div className={Style.templateImg}>
                  <Classic />
                </div>
                <div className={Style.description}>
                  <p>
                    Straight to the point, simple. This job template includes
                    all the basic sections for good job description.
                  </p>
                </div>
              </div>
              <MyButton
                onClick={() => {
                  // window.location.reload();
                  addBlockHelper("description");
                  addBlockHelper("skills_and_requirements");
                  addBlockHelper("benefits");
                  addBlockHelper("company_overview");
                  setSelectedBlock(blockOrder[0]);
                  // console.log(addBlockHelper);
                  props.setShowEmptyContentPageModal(false);
                }}
                size="medium"
                buttonStyle="primary"
              >
                Select
              </MyButton>
            </div>
            <div className={Style.templateOptionsWrapper}>
              <div className={Style.templateOption}>
                <h3>Start from scratch</h3>
                <div className={Style.imageDescriptionWrapper}>
                  <div className={Style.templateImg}>
                    <Empty />
                  </div>
                  <div className={Style.description}>
                    <p>
                      No need to follow a template, add each sections of your
                      job posting one by one.
                    </p>
                  </div>
                </div>
                <MyButton
                  onClick={() => {
                    setSideElem("addSection");
                    setOpenedDrawer(true);
                    props.setShowEmptyContentPageModal(false);
                  }}
                  size="medium"
                  buttonStyle="primary"
                >
                  Select
                </MyButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export { EmptyContentPageModal };
