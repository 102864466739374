import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import {
  useAPI,
  Loader,
  DragAndDropList,
  EditorContext,
  ContextRenderer,
  EditorNav,
  ThemeConfigCard,
  AnimateDiv,
  ViewContext,
  JobBasicInfoForm,
  BlockRenderer,
  JobInsightsSidebar,
  JobBasicInfoPreview,
  JobVersionExpiryModal,
  EmptyContentPageModal,
} from "components/lib";
import { themeList } from "./lib/themeList";
import Style from "./jobEditor.module.scss";
import Axios from "axios";
import { ScrollArea } from "@mantine/core";
import BasicInfoResponsiveHelper from "./basicInfoResponsiveHelper.js";
import JobEditorResponsiveHelper from "./jobeditorResponsiveHelper.js";
import ThemeEditorResponsiveHelper from "./themeEditorResponsiveHelper.js";
import { Stats } from "../../components/myTextEditor/lib/stats.js";
import {
  block_description_data_generator,
  block_key_responsabilities_data_generator,
  block_benefits_data_generator,
  block_skills_and_requirements_data_generator,
  block_image_data_generator,
  block_company_overview_data_generator,
  block_fine_print_data_generator,
  block_quote_data_generator,
} from "../../components//blockRenderer/blocks/_index.js";

import _ from "lodash";

export function JobEditor(props) {
  const viewContext = useContext(ViewContext);

  //Check if the app is in a sharelink mode
  const urlParams = new URLSearchParams(window.location.search);
  const share_link_mode =
    window.location.href.split("/")[3] === "sharelink" ? true : false;

  // EDITOR STATES
  const [blockDictionary, setBlockDictionary] = useState({});
  const [jobBasicInfo, setJobBasicInfo] = useState({});
  const [blockOrder, setBlockOrder] = useState([]);
  const [blockTypes, setBlockTypes] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState();
  const [stats, setStats] = useState(new Stats());
  const [currentStep, setCurrentStep] = useState("basic_information");
  const [sideElem, setSideElem] = useState("default");
  const [openedDrawer, setOpenedDrawer] = useState(false);
  const [coverPage, setCoverPage] = useState("noBanner");
  const [blockContextSwitcher, setBlockContextSwitcher] = useState("blockInfo");

  const contextScrollRef = useRef();
  const dragAndDropAreaRef = useRef();

  // Load job data
  const jobId = window.location.href.split("/")[4];
  localStorage.setItem('jobId', jobId);
  const loadData = useAPI("/api/job/" + jobId, "GET");
  const [data, setData] = useState("");

  const [jobData, setJobData] = useState(false);

  //check is data is loading
  useEffect(() => {
    if (!loadData.loading && loadData.data) {
      setData(loadData); /// TODO: Put only the data, not the loading + data in the setdata
    }
  }, [loadData]);

  //when done loading, set as job data.
  useEffect(() => {
    if (data.data) {
      setJobData(data.data); 
      const job_info = JSON.parse(data.data.job_info);
      // console.log(data.data.apply_button_status);
      // console.log(data.data.apply_button_value);
      if (job_info) {
        // console.log("This added a setter for the job elements");
        setJobBasicInfo(job_info.jobBasicInfo);
        setBlockDictionary(job_info.blockDictionary);
        setBlockOrder(job_info.blockOrder);
        blockOrder.forEach(element => {
          setSelectedBlock(job_info.blockOrder[element])
        });
        setSelectedBlock(job_info.blockOrder[0]);
        setTheme(job_info.theme);
      }
      // if (!job_info) {
        // console.log("this should add a title and basic info block");
      // }

      // Create a new sessions ID + log session Id in the page
    }
  }, [data]);

  //Content page modal
  const [showEmptyContentPageModal, setShowEmptyContentPageModal] =
    useState(false);

  useEffect(() => {
    if (currentStep === "block_editor" && blockOrder.length < 1) {
      setShowEmptyContentPageModal(true);
    }
  }, [currentStep]);

  // THEME STATE
  const [theme, setTheme] = useState(themeList("default"));
  const [previewMode, setPreviewMode] = useState("desktop");

  // Save job to server

  const [saveStatus, setSaveStatus] = useState("Saved");
  const [showJobVersionExpiryModal, setShowJobVersionExpiryModal] =
    useState(false);

  const saveJobtoServer = () => {
    setSaveStatus("Saving...");
    const newjobinfo = {
      blockDictionary: blockDictionary,
      blockOrder: blockOrder,
      theme: theme,
      jobBasicInfo: jobBasicInfo,
      //sessionId
    };

    Axios.patch("/api/job/" + window.location.href.split("/")[4], {
      job_info: JSON.stringify(newjobinfo),
    })
      .then((res) => {
        //setData(res);
        // console.log(res.data.data);
        const newData = data;
        newData.data.version = res.data.data.version;
        setData(newData);
        // const job_info = JSON.parse(newData.data.job_info);
        // console.log(job_info.theme);
        // console.log("Saved new version: ", data.data.version);
        setTimeout(() => {
          setSaveStatus("Saved");
        }, 800);
      })
      .catch((err) => {
        setSaveStatus("error saving, please reload");
        viewContext.notification.show(
          "There was an error saving your job ! Please reload",
          "error",
          true,
          "toast"
        );
        viewContext.handleError(err);
      });
  };

  const [saveTimer, setSaveTimer] = useState(null);
  const saveTriggers = [blockDictionary, blockOrder, theme, jobBasicInfo];
  const [firstSave, setFirstSave] = useState(saveTriggers.length);

  useEffect(() => {
    if (showJobVersionExpiryModal && saveTimer != null) {
      clearTimeout(saveTimer);
      return;
    }

    if (saveTimer === null) {
      if (firstSave > 0) {
        setFirstSave(firstSave - 1);
        return;
      }
      setSaveTimer(
        setTimeout(() => {
          saveJobtoServer();
          setSaveTimer(null);
        }, 1500)
      );
    } else {
      clearTimeout(saveTimer);
      setSaveTimer(
        setTimeout(() => {
          saveJobtoServer();
          setSaveTimer(null);
        }, 1500)
      );
    }
  }, saveTriggers); // Pass in empty array to run useEffect only on mount.

  /////// Hearthbeat :

  // check server version on save and update
  // DONE

  // hearthbeat the server and compare with version
  useEffect(() => {
    const interval = setInterval(() => {
      Axios.get("/api/jobMetadata/" + jobData.id, {})
        .then((res) => {
          if (res.data.version !== jobData.version) {
            console.log(
              "Job version is out of sync. Close other tabs or refresh"
            );
            setShowJobVersionExpiryModal(true);
          }
        })
        .catch((err) => {
          viewContext.handleError(err);
        });
    }, 5000);
    return () => clearInterval(interval);
  });

  //Check types of blocks
  useEffect(() => {
    // console.log("the checker of blockOrder ran");
    let NewblockTypes = [];
    for (let index = 0; index < blockOrder.length; index++) {
      if (blockDictionary[blockOrder[index]]?.type) {
        NewblockTypes.push(blockDictionary[blockOrder[index]]?.type);
      }
    }
    // console.log(NewblockTypes);
    setBlockTypes(NewblockTypes);
  }, [blockOrder.toString()]); //added  toString to force the useEffect to run.

  // delete block
  const deleteBlockHelper = (blockId) => {
    if (blockOrder.includes(blockId)) {
      setSelectedBlock(null);
      console.log("Deleting... ", blockId);


      if (blockDictionary[blockId].type === "image") {
        if (blockDictionary[blockId].content.file !== null) {
          Axios.delete("/api/userImage/" + blockDictionary[blockId].content.file.id)
          .then((res) => {
            console.log("Image deleted: " + blockDictionary[blockId].content.file.id);
          })
          .catch((err) => {
            viewContext.handleError(err);
          });
        }
        if (blockDictionary[blockId].content.cropped_file !== null) {
          Axios.delete("/api/userImage/" + blockDictionary[blockId].content.cropped_file.id)
          .then((res) => {
            console.log("Image deleted: " + blockDictionary[blockId].content.cropped_file.id);
          })
          .catch((err) => {
            viewContext.handleError(err);
          });
        }
      }
  
      // Remove from blockOrder
      const tempBlockOrder = _.without(blockOrder, blockId); // lodash's without function to remove the blockId from the array
      setBlockOrder(tempBlockOrder);
  
      if (tempBlockOrder.length > 0) {
        setSelectedBlock(blockOrder[0]);
      } else {
        // console.log("set block to previous block: ", tempBlockOrder.length);
        setSelectedBlock(null);
      }
  
      const tempBlockDictionary = _.omit(blockDictionary, blockId); // lodash's omit function to remove the blockId from the object
      setBlockDictionary(tempBlockDictionary);
  
    } else {
      // console.log("Nothing to delete");
    }
  };
  

  const addBlockHelper = (block_type) => {
    let block_data = {};

    switch (block_type) {
      case "description":
        block_data = block_description_data_generator();
        break;
      case "key_responsabilities":
        block_data = block_key_responsabilities_data_generator();
        break;
      case "skills_and_requirements":
        block_data = block_skills_and_requirements_data_generator();
        break;
      case "benefits":
        block_data = block_benefits_data_generator();
        break;
      case "image":
        block_data = block_image_data_generator();
        break;
      case "company_overview":
        block_data = block_company_overview_data_generator();
        break;
      case "fine_print":
        block_data = block_fine_print_data_generator();
        break;
      case "quote":
        block_data = block_quote_data_generator();
        break;
      default:
        break;
    }

    //update the blockDictionary
    const temp_blockDictionary = blockDictionary;
    temp_blockDictionary[block_data.key] = block_data.data;
    setBlockDictionary(temp_blockDictionary);

    // update the blockDictionary order.
    const temp_blockOrder = blockOrder;
    temp_blockOrder.push(block_data.key);

    // update everything
    setBlockOrder(temp_blockOrder);
    setSelectedBlock(block_data.key);
  };

  // PREVIEW MANAGEMENT
  //Used to show a preview of the image being croped
  const [previewCrop, setPreviewCrop] = useState(null);

  useEffect(() => {
    setPreviewCrop(null);
  }, [selectedBlock, currentStep]);

  // EDITOR CONTEXT (STORE)
  const editor_context_store = {
    jobData: jobData,
    setJobData: setJobData,
    jobBasicInfo: jobBasicInfo,
    setJobBasicInfo: setJobBasicInfo,
    blockDictionary: blockDictionary,
    setBlockDictionary: setBlockDictionary,
    blockOrder: blockOrder,
    setBlockOrder: setBlockOrder,
    selectedBlock: selectedBlock,
    setSelectedBlock: setSelectedBlock,
    currentStep: currentStep,
    setCurrentStep: setCurrentStep,
    previewCrop: previewCrop,
    setPreviewCrop: setPreviewCrop,
    theme: theme,
    setTheme: setTheme,
    blockTypes: blockTypes,
    setBlockTypes: setBlockTypes,
    contextScrollRef: contextScrollRef,
    deleteBlockHelper: deleteBlockHelper,
    addBlockHelper: addBlockHelper,
    share_link_mode: share_link_mode,
    dragAndDropAreaRef: dragAndDropAreaRef,
    stats: stats,
    setStats: setStats,
    blockContextSwitcher: blockContextSwitcher,
    setBlockContextSwitcher: setBlockContextSwitcher,
  };

  // Check if there are blocks in the editor
  let isBlocks = blockOrder.length > 0;

  // TODO (Cedric M): Figure out routing for this.
  return (
    <div className={Style.editorWrapper}>
      {jobData === false && <Loader />}
      {jobData !== false && (
        <EditorContext.Provider value={editor_context_store}>
          <JobVersionExpiryModal
            showJobVersionExpiryModal={showJobVersionExpiryModal}
            setShowJobVersionExpiryModal={setShowJobVersionExpiryModal}
          />
          <EmptyContentPageModal
            showEmptyContentPageModal={showEmptyContentPageModal}
            setShowEmptyContentPageModal={setShowEmptyContentPageModal}
            setSideElem={setSideElem}
            setOpenedDrawer={setOpenedDrawer}
          />
          <EditorNav
            jobName={jobData.name}
            currentStep={currentStep}
            saveStatus={saveStatus}
            saveJobCallback={saveJobtoServer}
            isBlocks={blockOrder.length > 0}
            jobId={jobId}
            
          />

          {currentStep === "basic_information" && (
            <BasicInfoResponsiveHelper
              basicInfoMain={<JobBasicInfoForm />}
              basicInfoPreview={<JobBasicInfoPreview />}
            />
          )}
          {currentStep === "block_editor" && (
            <JobEditorResponsiveHelper
              sideElem={sideElem}
              setSideElem={setSideElem}
              openedDrawer={openedDrawer}
              setOpenedDrawer={setOpenedDrawer}
              mainContent={<DragAndDropList />}
              blockInfoAndConfig={
                <ContextRenderer
                  blockType={
                    selectedBlock !== null
                      ? blockDictionary[selectedBlock]?.type
                      : null
                  }
                  blockId={selectedBlock}
                  setOpenedDrawer={setOpenedDrawer}
                />
              }
              tips={<JobInsightsSidebar />}
              addSection={
                <ContextRenderer blockType={null} blockId={selectedBlock} setOpenedDrawer={setOpenedDrawer}/>
              }
            />
          )}
          {currentStep === "theme_selector" && isBlocks && (
            <ThemeEditorResponsiveHelper
              previewMode={previewMode}
              setPreviewMode={setPreviewMode}
              coverPage={coverPage}
              setCoverPage={setCoverPage}
              settingSpace={
                <ThemeConfigCard
                  themeList={themeList}
                  setPreviewMode={setPreviewMode}
                  previewMode={previewMode}
                  coverPage={coverPage}
                  setCoverPage={setCoverPage}
                />
              }
            />
          )}
        </EditorContext.Provider>
      )}
    </div>
  );
}
